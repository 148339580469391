import React, { createContext, useContext, useState, useEffect } from 'react';

const BridgeContext = createContext();

export function useBridge() {
    return useContext(BridgeContext);
}

export const BridgeProvider = ({ children }) => {
  const defaultState = {
    source: null,
    destination: null,
  };

  const [bridgePlan, setBridgePlan] = useState(defaultState);

  const setBridgeSelection = (type, value) => {
    let newSelection = {...bridgePlan};
    newSelection[type] = value;
    setBridgePlan(newSelection);
  };

  return (
    <BridgeContext.Provider value={{ bridgePlan, setBridgeSelection}}>
      {children}
    </BridgeContext.Provider>
  );
};