import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { IoClose } from "react-icons/io5";
import { FaXmark } from "react-icons/fa6";


import { useSelection } from "../contexts/SelectionContext";
import './Modal.css';
import { useModal } from "../contexts/ModalContext";

function Modal() {
  const {
    modalOpen,
    setModalOpen,
    selectionType,
    modalOptions,
    modalTitle,
  } = useModal();

  const modalRef = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setModalOpen(false);
      }
    };

    const handleEscape = (event) => {
      if (event.key === "Escape") {
        setModalOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleEscape);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleEscape);
    };
  }, [setModalOpen]);

  const close = () => {
    setModalOpen(false);
  }

  const callbackWrapper = (key, callback) => {
    return () => {
      callback(key)
      close();
    }
  }

  const OptionsList = useCallback(() => {
    if (!modalOptions || !Object.keys(modalOptions).length) {
      return <div>No options available</div>;
    }

    const renderedOptions = Object.entries(modalOptions).map(([key, { icon, callback, alternateLabel }], index) => (
      <div key={key} onClick={callbackWrapper(key, callback)} className="option">
        <div>
          <img src={icon} alt={key} />
        </div>
        <div>
          {key}
        </div>
      </div>
    ));
    
    return renderedOptions;
  }, [modalOptions]);

  if (!modalOpen) {
    return null;
  }

  return (
    <div ref={modalRef} class={modalOpen ? 'modal modal-visible' : 'modal modal-hidden'}>
      <div class='exit' onClick={close}>
        <FaXmark size={35} />
      </div>
      <div class='modal-title'>
        {modalTitle}
      </div>
      <div class='options'>
        <OptionsList />
      </div>
    </div>
  )
}

export default Modal;