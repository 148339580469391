import phantomIcon from '../assets/images/wallet-phantom.png';
import leatherIcon from '../assets/images/wallet-leather.png';
import xverseIcon from '../assets/images/wallet-xverse.png';
import solanaIcon from '../assets/images/icon-solana.png';
import stacksIcon from '../assets/images/icon-stacks.png';

export const Options = ({ ...settings }) => {
  const {
    requestWallet,
    upsertSelection
  } = settings;

  return {
    "stacks testnet": {
      icon: stacksIcon,
      callback: (network) => {
        upsertSelection("network", network);
      },
      wallets: {
        "leather": {
          icon: leatherIcon,
          callback: (wallet) => {
            upsertSelection("wallet", wallet);
            requestWallet("leather");
          },
        },
        // "xverse": {
        //   icon: xverseIcon,
        //   callback: (wallet) => {
        //     upsertSelection("wallet", wallet);
        //     requestWallet("leather");
        //   }
        // }
      },
      assets: {
        "stx": {
          balance: 0,
          icon: stacksIcon,
          callback: (asset) => {
            upsertSelection("asset", asset);
          },
          pairs: {
            "bstx": true
          }
        },
        "bsol": {
          balance: 0,
          icon: solanaIcon,
          callback: (asset) => { 
            upsertSelection("asset", asset);
          },
          pairs: {
            "sol": true
          }
        },
      }
    },
    "solana devnet": {
      icon: solanaIcon,
      callback: (network) => {
        upsertSelection("network", network);
      },
      wallets: {
        "phantom": {
          icon: phantomIcon,
          callback: () => {
            upsertSelection("wallet", "phantom");
            requestWallet("phantom");
          }
        }
      },
      assets: {
        "bstx": {
          balance: 0,
          icon: stacksIcon,
          callback: (asset) => {
            upsertSelection("asset", asset);
          },
          pairs: {
            "stx": true
          }
        },
        "sol": {
          balance: 0,
          icon: solanaIcon,
          callback: (asset) => {
            upsertSelection("asset", asset);
          },
          pairs: {
            "bsol": true
          }
        },
      }
    }
  }
}