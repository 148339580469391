import { useEffect, useRef, useState } from 'react';

import { MdNavigateNext } from "react-icons/md";
import { FaRegCircle } from "react-icons/fa6";
import { FaWallet } from "react-icons/fa";
import { FaCoins } from "react-icons/fa";
import { FaAngleRight } from "react-icons/fa6";
import { FaAngleDown } from "react-icons/fa6";






import SelectWithModal from "./SelectWithModal";
import { useBridge } from '../contexts/BridgeContext';
import { useWallets } from '../contexts/WalletsContext';
import { useSelection } from '../contexts/SelectionContext';
import { Options } from '../lib/options';

import './SelectionFlow.css';

function SelectionFlow({ type, disable = false }) {
  const flowRef = useRef(null);
  const [walletTitle, setWalletTitle] = useState("Connect wallet");

  const { wallets, requestWallet, walletRequests } = useWallets();
  const { selection, upsertSelection } = useSelection();
  const { bridgePlan, setBridgeSelection } = useBridge();
  const options = Options({
    requestWallet,
    upsertSelection,
  });

  // only for destination flows
  const filteredAssetOptions = () => {
    const key = type === "source" ? "destination" : "source";

    if (type == "source" || bridgePlan[key]?.asset == null) {
      return options[selection.network]?.assets || {}
    }

    const sourcePairs = options[bridgePlan[key].network]
      .assets[bridgePlan[key].asset]
      .pairs
    
    if (!selection.wallet) {
      return {}
    }

    const destAssets = options[selection.network].assets;
    const tradableAssets = {};

    for (const asset in destAssets) {
      if (sourcePairs[asset]) {
        tradableAssets[asset] = destAssets[asset];
      }
    }

    return tradableAssets;
  }

  const filteredNetworkOptions = () => {
    const key = type === "source" ? "destination" : "source";

    if (type == "source" || !bridgePlan[key]?.network) {
      return options || {}
    }

    const filteredOptions = {...options};
    delete filteredOptions[bridgePlan[key].network];
    return filteredOptions;
  }

  const selectAssetKey = () => {
    if (type === "source") {
      return selection.network;
    }

    return bridgePlan.source?.asset;
  }

  useEffect(() => {
    setBridgeSelection(type, selection)
  }, [selection.amount, selection.asset, selection.wallet, selection.network]);

  useEffect(() => {
    if (flowRef.current) {
      const inputs = flowRef.current.querySelectorAll('input');
      inputs.forEach(input => {
        input.disabled = disable; // Set disabled based on the prop
      });
    }
  }, [disable]);

  return (
    <div ref={flowRef} class="selection-flow">
      <div>
        <SelectWithModal 
          select="network" 
          title="Select network"
          icon=<FaRegCircle size={32} />
          options={filteredNetworkOptions()}/>
      </div>
      <div class="full-screen-arrow">
        <FaAngleRight color='white' size={20} />
      </div>
      <div class="small-screen-arrow">
        <FaAngleDown color='white' size={20} />
      </div>
      <div>
        <SelectWithModal 
          key={selection.network}
          disabled={!selection.network}
          select="wallet" 
          title="Connect wallet"
          icon=<FaWallet size={32} />
          options={options[selection.network]?.wallets || {}}/>
      </div>
      <div class="full-screen-arrow">
        <FaAngleRight color='white' size={20} />
      </div>
      <div class="small-screen-arrow">
        <FaAngleDown color='white' size={20} />
      </div>
      <div>
        <SelectWithModal 
          key={selectAssetKey()}
          disabled={!selection.wallet}
          select="asset" 
          title="Select asset"
          icon=<FaCoins size={32} />
          options={filteredAssetOptions()}/>
      </div>
    </div>
  );
}

export default SelectionFlow;