import { MdOutlineKeyboardDoubleArrowDown } from "react-icons/md";
import { FaAnglesDown, FaD } from "react-icons/fa6";
import { FaSquareXTwitter } from "react-icons/fa6";
import { FaDiscord } from "react-icons/fa6";
import { FaTelegram } from "react-icons/fa6";
import { FaGithub } from "react-icons/fa6";



import SelectionFlow from './SelectionFlow';
import Quote from './Quote';
import { SelectionProvider } from "../contexts/SelectionContext";
import { useBridge } from "../contexts/BridgeContext";
import { useWallets } from "../contexts/WalletsContext";
import './Bridge.css';
import InputAmount from "./InputAmount";
import { useCallback, useEffect, useState } from "react";
import { ModalProvider } from "../contexts/ModalContext";
import Modal from "./Modal";

import logo from '../assets/images/logo-cropped.svg';

function Bridge() {
  const { bridgePlan } = useBridge();
  const [ amount, setAmount ] = useState(0);
  const [ balanceTooLow, setBalanceTooLow ] = useState(false);
  const [ showQuote, setShowQuote ] = useState(false);
  const { wallets } = useWallets();

  useEffect(() => {
    if (bridgePlan.source?.asset && bridgePlan.destination?.asset && amount > 0) {
      setShowQuote(true);
    } else {
      setShowQuote(false);
    }
  }, [bridgePlan, amount]);

  useEffect(() => {
    if (amount == 0 || (amount >= 0 && amount <= sourceAssetBalance())) {
      setBalanceTooLow(false);
    } else {
      setBalanceTooLow(true);
    }
  }, [amount])

  const sourceAccount = useCallback(() => {
    const wallet = bridgePlan.source?.wallet;
    return  wallets[wallet]?.publicKey.toString();
  });

  const sourceAssetBalance = useCallback(() => {
    const wallet = bridgePlan.source?.wallet;
    const asset = bridgePlan.source?.asset;
    if (!wallet || !asset) {
      return;
    }

    if (asset in wallets[wallet]?.balances) {
      return wallets[wallet]?.balances[asset].toString();
    }

    return "0";
  });

  const destinationAccount = useCallback(() => {
    const wallet = bridgePlan.destination?.wallet;
    return wallets[wallet]?.publicKey.toString();
  });

  const destinationAssetBalance = useCallback(() => {
    const wallet = bridgePlan.destination?.wallet;
    const asset = bridgePlan.destination?.asset;
    if (!wallet || !asset) {
      return;
    }

    if (asset in wallets[wallet]?.balances) {
      return wallets[wallet]?.balances[asset].toString();
    }

    return "0";
  });

  function formatAccount(account) {
    if (account == undefined) {
      return;
    }
    if (account.length > 13) {
      let start = account.slice(0, 5);
      let end = account.slice(-5);
      return start + "..." + end;
    } else {
      return account;
    }
  }

  const [ amountAfterFees, setAmountAfterFees ] = useState(0);
  const [ ourFees, setOurFees ] = useState(0);
  const [ networkFees, setNetworkFees ] = useState(0);

  useEffect(() => {
    if (amount > 0) {
      setOurFees(0.01 * amount);
      setNetworkFees(0.01 * amount);
      setAmountAfterFees(amount - (0.01 * amount) - (0.01 * amount));
    }
  }, [amount]);

  return (
    <div class="bridge" style={{ 
      // backgroundImage: `url(${process.env.PUBLIC_URL + '/images/background.jpeg'})`,
      // backgroundSize: 'cover',
    }}>
      <div class="header">
        <div class="logo side-piece">
          <img src={logo} alt="logo" height='70em' />
        </div>
        <div class="header-title">
          Byphrost
        </div>
        <div class="nav side-piece">
          <div class="nav-item">
            <a href="https://forms.gle/sAvXu4PQ4KZS8nGVA" target="_blank">Transaction Explorer</a>
          </div>
          <div class="nav-item">
            <a target="_blank" href="https://scrawny-scion-8fd.notion.site/Byphrost-Bridge-6c95833f34f140b281f19b1bb36b6505" target="_blank">Documentation</a>
          </div>
          <div class="nav-item">
            <a target="_blank" href="https://forms.gle/oDCrmbV1HbsEMSV68">Support</a>
          </div>
        </div>
      </div>
      <div class="announcement">
        <em>Bridge between Stacks and Solana effortlessly.</em>
      </div>
      <ModalProvider>
        <div class="order-wrapper">
          <div class="box-top"></div>
          <div class="order-input">
            <div class="title">
              From
            </div>
            <SelectionProvider type="source">
              <SelectionFlow type="source" />
            </SelectionProvider>
            <div>
              <div class={`${sourceAccount() ? "account-info" : "hidden"}`}>
                <label>account</label> 
                <div style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}>
                  <div style={{
                    height: '2em',
                  }}>
                    {formatAccount(sourceAccount())}
                  </div>
                  <div class="account-options">
                    <a href="" class="account-button">Copy address</a>
                    <a href="" class="account-button">Transactions</a>
                    <a href="" class="account-button">Disconnect</a>
                  </div>
                </div>
              </div>
              <div class={`${sourceAssetBalance() ? "account-info" : "hidden"}`}>
                <label>balance</label>
                <span>
                  {sourceAssetBalance()} {bridgePlan.source?.asset}
                </span>
              </div>
              <div class={`${sourceAssetBalance() ? "account-info" : "hidden"}`}>
                <label>send</label>
                <div style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '16em'
                }}>
                  <div style={{
                    height: '2em',
                  }}>
                    <InputAmount setAmount={setAmount} />
                  </div>

                  <div class={balanceTooLow ? `error` : `no-error`}>
                    That amount is greater than your account balance.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="box-bottom"></div>
        </div>
        <div style={{
          padding: '1em',
        }}>
          <FaAnglesDown size={30} color="white" />
        </div>
        <div class="order-wrapper">
          <div class="box-top"></div>
          <div class={`order-input`}>
            <div class="title">
              To 
            </div>
            <SelectionProvider type="destination">
              <SelectionFlow type="destination" />
            </SelectionProvider>
            <div>
              <div class={`${destinationAccount() ? "account-info" : "hidden"}`}>
                <label>account</label> 
                <div style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}>
                  <div style={{
                    height: '2em',
                  }}>
                    {formatAccount(destinationAccount())}
                  </div>
                  <div class="account-options">
                    <a href="" class="account-button">Copy address</a>
                    <a href="" class="account-button">Transactions</a>
                    <a href="" class="account-button">Disconnect</a>
                  </div>
                </div>
              </div>
              <div class={`${destinationAssetBalance() ? "account-info" : "hidden"}`}>
                <label>balance</label> 
                {destinationAssetBalance()} {bridgePlan.destination?.asset}
              </div>
              <div class={`${sourceAssetBalance() && destinationAssetBalance() ? "account-info" : "hidden"}`}>
                <label>Bridge Fee</label>
                {ourFees} {bridgePlan.source?.asset} 
              </div>
              <div class={`${sourceAssetBalance() && destinationAssetBalance() ? "account-info" : "hidden"}`}>
                <label>Network Fee</label>
                {networkFees} {bridgePlan.source?.asset} 
              </div>
              <div class={`${sourceAssetBalance() && destinationAssetBalance() ? "account-info" : "hidden"}`}>
                <label>receive</label>
                <strong>{amountAfterFees}</strong>&nbsp;{bridgePlan.source?.asset}
              </div>
            </div>

            <div class={`${showQuote && !balanceTooLow ? "quote-info" : "hidden"}`}>
              <a href="https://forms.gle/sAvXu4PQ4KZS8nGVA" target="_blank" class="button waiting-pulse">Send</a>
            </div>
          </div>
          <div class="box-bottom"></div>

        </div>

        <Modal />
      </ModalProvider>
      <div class="socials">
        <a href="https://discord.gg/QXdKtcPkS7" target="_blank"><FaDiscord color="white" size={28} /></a>
        <a href="https://t.me/+mZ49b4Xfv8piYWEx" target="_blank"><FaTelegram color="white" size={28} /></a>
        <a href="https://github.com/byphrost"><FaGithub color="white" size={28} /></a>
      </div>
    </div>
  );
}

export default Bridge;