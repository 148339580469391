import React, { createContext, useContext, useState, useEffect } from 'react';

import Modal from '../components/Modal';

const ModalContext = createContext();

export function useModal() {
    return useContext(ModalContext);
}

export const ModalProvider = ({ children }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectionType, setSelectionType] = useState(null);
  const [modalOptions, setModalOptions] = useState({});
  const [modalTitle, setModalTitle] = useState(null);

  const openModal = (type, options, title) => {
    setSelectionType(type);
    setModalOptions(options);
    setModalTitle(title);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  }

  return (
    <ModalContext.Provider value={{ modalOpen, setModalOpen, selectionType, modalOptions, modalTitle, openModal, closeModal }}>
      {children}
    </ModalContext.Provider>
  );
};