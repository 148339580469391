import React, { createContext, useContext, useEffect, useState } from 'react';
import { useBridge } from './BridgeContext';

const SelectionContext = createContext();

export function useSelection() {
    return useContext(SelectionContext);
}

export const SelectionProvider = ({ children, type }) => {
  const defaultState = {
    network: null,
    wallet: null,
    asset: null,
    amount: 0
  };

  const { bridgePlan, setBridgeSelection } = useBridge();
  const [selection, setSelection] = useState(() => {
    if (bridgePlan[type]) {
      return bridgePlan[type];
    } else {
      return defaultState;
    }
  });

  useEffect(() => {
    setBridgeSelection(type, selection)
  }, [selection.amount, selection.asset, selection.wallet, selection.network]);
  
  // this upsert ensures ordering of selection flow
  const upsertSelection = (key, value) => {
    let newSelection = {...selection};
    if (key === "amount") {
      newSelection["amount"] = value;
    } else if (key === "asset") {
      newSelection["amount"] = 0;
      newSelection["asset"] = value;
    } else if (key === "wallet") {
      newSelection["amount"] = 0;
      newSelection["asset"] = null;
      newSelection["wallet"] = value;
    } else if (key === "network") {
      newSelection["amount"] = 0;
      newSelection["asset"] = null;
      newSelection["wallet"] = null;
      newSelection["network"] = value;
    }
    setSelection(newSelection);
  };

  return (
      <SelectionContext.Provider value={{ selection, upsertSelection, setSelection }}>
        {children}
      </SelectionContext.Provider>
  );
};