import {
  AppConfig,
  UserSession,
  AuthDetails,
  showConnect,
} from "@stacks/connect";
import React, { createContext, useContext, useEffect, useState } from 'react';
import { Connection, clusterApiUrl } from '@solana/web3.js';
import { AccountsApi, Configuration } from '@stacks/blockchain-api-client';

const fetchStxBalance = async (address) => {
  const apiConfig = new Configuration({
    fetchApi: fetch,
    basePath: 'https://stacks-node-api.testnet.stacks.co', // Use https://stacks-node-api.testnet.stacks.co for testnet
  });
  const accountsApi = new AccountsApi(apiConfig);

  try {
    const response = await accountsApi.getAccountBalance({
      principal: address
    });
    return response.stx.balance; // The balance is returned in microstacks
  } catch (error) {
    console.error('Failed to fetch STX balance:', error);
    return null; // or handle error appropriately
  }
};

const WalletsContext = createContext();

export function useWallets() {
    return useContext(WalletsContext);
}

export const WalletsProvider = ({ children }) => {
  const [wallets, setWallets] = useState({});
  const [walletRequests, setWalletRequests] = useState([]);

  const requestWallet = (wallet) => {
    setWalletRequests([...walletRequests, wallet]);
  }

  const appConfig = new AppConfig(["store_write"]);
  const userSession = new UserSession({ appConfig });

  const appDetails = {
    name: "Hello Stacks",
    icon: "https://freesvg.org/img/1541103084.png",
  };

  const connectToLeather = () => {
    if (userSession.isUserSignedIn()) {
      return;
    }

    showConnect({
      appDetails,
      onFinish: () => {
        const userData = userSession.loadUserData()
        const address = userData.profile.stxAddress.testnet;
        fetchStxBalance(address).then((balance) => {
          let newWallets = {...wallets};
          if (!newWallets["leather"]) {
            newWallets["leather"] = {balances: {}};
          }

          newWallets["leather"].connected = true;
          newWallets["leather"].publicKey = address;
          newWallets["leather"].balances["stx"] = balance / 10 ** 6;
          setWallets(newWallets);
        })
      },
      userSession,
    })
  };

  const connectToPhantom = async () => {
    const resp = await window.phantom.solana.connect()
    const connection = new Connection(clusterApiUrl('devnet'), 'confirmed');
    const balance = await connection.getBalance(resp.publicKey);

    let newWallets = {...wallets};
    if (!newWallets["phantom"]) {
      newWallets["phantom"] = {balances: {}};
    }

    newWallets["phantom"].connected = true;
    newWallets["phantom"].publicKey = resp.publicKey;
    newWallets["phantom"].balances["sol"] = balance / 10 ** 9;

    setWallets(newWallets);
  }

  useEffect(() => {
    const request = walletRequests[walletRequests.length - 1];

    const connectWallet = async () => {
      if (request === "phantom") {
        await connectToPhantom()
      } else if (request === "leather") {
        connectToLeather()
      } else if (request === "xverse") {
        connectToLeather()
      }
    }

    connectWallet();
  }, [walletRequests.length]);

  return (
    <WalletsContext.Provider value={{ wallets, walletRequests, requestWallet }}>
      {children}
    </WalletsContext.Provider>
  );
};