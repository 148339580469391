import React, { useCallback, useEffect } from "react";
import PulseLoader from "react-spinners/PulseLoader";

import Modal from "./Modal";
import './SelectWithModal.css';
import { useWallets } from "../contexts/WalletsContext";
import { useModal } from "../contexts/ModalContext";
import { useSelection } from "../contexts/SelectionContext";


function SelectWithModal({ select, title, icon, options, disabled = false}) {
  const [ modalSelection, setModalSelection ] = React.useState(null);
  const [ loading, setLoading ] = React.useState(false);
  const { wallets } = useWallets();
  const { openModal, closeModal } = useModal();
  const { selection } = useSelection();

  const open = () => {
    openModal(select, options, title);
  }

  useEffect(() => {
    setModalSelection(selection[select]);
  }, [selection[select]]);

  const Icon = useCallback(() => {
    return modalSelection ? <img src={options[modalSelection].icon} /> : icon;
  });

  useEffect(() => {
    if (modalSelection) {
      closeModal();
      setLoading(true);
    }
  }, [options, modalSelection]);

  useEffect(() => {
    if (select === "wallet") {
      if (wallets[modalSelection]?.publicKey) {
        setLoading(false);
      }
    } else {
      setLoading(false);
    } 
  }, [options, modalSelection, wallets[modalSelection]?.publicKey]);

  const Name = useCallback(() => {
    return modalSelection || title;
  });

  const disabledClasses = disabled ? 'disabled' : '';

  const waiting = () => {
    if (disabled || loading) {
      return '';
    }

    if (modalSelection) {
      return '';
    }

    return 'waiting-pulse';
  }

  return (
    <div class={disabledClasses}>
      <div class={`select-with-modal ${waiting()}`} onClick={disabled ? null : open}>
        <div class={loading ? `loading` : `hidden`}>
          <PulseLoader
            aria-label="Loading Spinner"
            data-testid="loader"
            color={"black"}
            speedMultiplier={.5}
            size={15} />
        </div>
        <div class={!loading ? `` : `hidden`}>
          <Icon />
        </div>
        <div class={!loading ? `` : `hidden`}>
          <Name />
        </div>
      </div>
    </div>
  );
}

export default SelectWithModal;