import Bridge from './components/Bridge'
import { BridgeProvider } from './contexts/BridgeContext';
import './App.css';
import { WalletsProvider } from './contexts/WalletsContext';
import { useEffect } from 'react';
import ReactGA from "react-ga4";


function App() {
  useEffect(() => {
    localStorage.removeItem('blockstack-session');
    document.title = "Byphrost Bridge"
    ReactGA.initialize("G-Q123WFDDE4");
  }, []); 

  return (
    <WalletsProvider>
      <BridgeProvider>
        <Bridge />
      </BridgeProvider>
      
    </WalletsProvider>
  );
}

export default App;
