import { useSelection } from '../contexts/SelectionContext';

import './InputAmount.css';

function InputAmount({ setAmount }) {
  const set = (event) => {
    console.log('event.target.value', event.target.value);
    setAmount(Number(event.target.value))
  }

  return (
    <input type="number" onChange={set} placeholder={0} />
  );
}

export default InputAmount;